<template>
  <div class="project" :class="`project-${index}`" @mouseenter="switchVideo(true)" @mouseleave="switchVideo(false)">
    <a :href="link" target="_blank" :class="`${link && link != '' ? '' : 'no-link'}`">
      <div class="desktop-wrapper">
        <div class="image-wrapper" :class="`image-wrapper-${index}`">
          <img class="border" src="/images/frame.png" />
          <img :src="cover" />
          <img class="hover" :src="hoverImage" v-if="hoverImage" />
          <video class="hover" playsinline muted loop autoplay v-if="hoverVideo">
            <source :src="hoverVideo" type="video/mp4">
          </video>
        </div>
        <h1 :class="`locale-${locale}`" v-if="link == null"><span v-for="(part, index) in nameSeparated">{{part}} </span></h1>
        <h1 class="separated" :class="[ `locale-${locale}`, { 'customLetterSpacing': customLetterSpacing} ]" v-if="link != null">
          <span v-for="(part, index) in nameSeparated"><span :key="index" 
             :class="`${isHovered ? 'hovered' : ''}`"
             class="nameSeparatedLine pseudo-underline hide-line">{{part}}</span><br></span>
        </h1>
        <p class="description" :class="`locale-${locale}`" v-html="description"></p>
        <p class="achievement" v-html="achievement" :class="`achievement-${index} locale-${locale}`"></p>
      </div>

      <div class="mobile-wrapper">
        <h1 :class="`locale-${locale}`" v-html="mobileName"></h1>
        <p class="description" :class="`locale-${locale}`" v-html="description"></p>
        <div class="image-wrapper" :class="`image-wrapper-${index}`">
          <img class="border" src="/images/frame.png" />
          <img :src="cover" v-if="!hoverImage && !hoverVideo" />
          <img :src="hoverImage" v-if="hoverImage" />
          <video playsinline muted loop autoplay v-if="hoverVideo">
            <source :src="hoverVideo" type="video/mp4">
          </video>
          <p class="achievement" v-html="achievementMobile || achievement" :class="`achievement-${index} locale-${locale}`"></p>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: "project",
    props: ['name', 'description', 'achievement',
      'index', 'link', 'mobileName', 'cover', 'hoverVideo', 'hoverImage',
      'nameSeparated', 'achievementMobile', 'customLetterSpacing'],
    data() {
      return {
        isHovered: false
      }
    },
    computed: {
      ...mapGetters({
        locale: 'getLocale'
      })
    },
    methods: {
      switchVideo(isHovered) {
        if (this.$refs.video) {
          if (isHovered) {
            let playPromise =  this.$refs.video.play();
            if (playPromise) {
              playPromise.then(_ => {

              }).catch(error => {
                this.$refs.video.play();
              });
            }
          } else {
            this.$refs.video.pause();
          }
        }
        this.isHovered = isHovered;
      }
    }
  }
</script>

<style lang="scss">

  .project {
    // float: left;
    position: relative;
    max-width: 360px;
    margin-top: 65px;
    margin-bottom: 65px;

    @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
      // width: 130px;
      width: 172px;
      margin-top: 25px;
      margin-bottom: 25px;
      // top: -40px;
    }

    @media all and (max-width: $screen-phone-max-width) {
      margin-top: 20px;
      margin-bottom: 20px
    }

    a {
      text-decoration: none;
      cursor: pointer;

      &:hover {
        h1 {
          text-decoration: none;
        }
      }
    }

    a {
      &.no-link {
        cursor: unset;
        h1 {
          text-decoration: none;
        }

        &:hover {
          h1 {
            text-decoration: none;
          }
        }
      }
    }

    &:hover {
      .desktop-wrapper {
        .image-wrapper {
          .hover {
            opacity: 1;
          }
        }
      }
    }

    .desktop-wrapper {
      .image-wrapper {
        position: relative;
        width: 266px;
        height: 540px;
        border-radius: 40px;
        box-shadow: 4px 4px 15px rgba(23, 26, 198, 0.25), -10px 10px 20px rgba(14, 18, 214, 0.4), -20px 10px 30px rgba(19, 22, 184, 0.25), -35px 20px 50px rgba(36, 39, 196, 0.4), -60px 30px 70px #494CD7;

        .hover {
          opacity: 0;
          transition: opacity 0.5s ease;
        }

        img, video {
          position: absolute;
          top: 13px;
          left: 15px;
          width: 89%;
          height: 95%;

          &.border {
            width: 100%;
            height: 100%;
            top: 0;
            left: -1px;
            z-index: 1;
          }

          @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
            top: 7px;
            left: 8px;
          }
        }

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
          width: 150px;
          height: 303px;
        }
      }

      h1 {
        font-size: $desktop-h1-fontsize;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.1;
        letter-spacing: normal;
        color: #ffffff;
        text-decoration: underline;
        width: 284px;
        margin-top: 30px;
        margin-bottom: 13px;
        position: relative;

        &.separated {
          a {
            color: white;
            line-height: 110%;
          }

        }

        &.customLetterSpacing {
          letter-spacing: -1px;

          @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
            letter-spacing: -0.5px;
          }
        }

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
          font-size: $tablet-h1-fontsize;
          line-height: 28px;
          width: 147px;
          // margin-top: 79px;
        }
      }

      .description {
        font-size: $desktop-p-fontsize;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: normal;
        color: #ffffff;
        width: 90%;
        margin-bottom: 20px;
        margin-top: 20px;

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
          font-size: $tablet-p-fontsize;
          line-height: 17px;
        }
      }

      .achievement {
        font-size: $desktop-h1-fontsize;
        font-weight: 800;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.1;
        letter-spacing: normal;
        color: #000000;
        width: 90%;

        // &.locale-ru {
        //   @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
        //     font-size: 22px;
        //     line-height: 22px;
        //   }
        // }

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
          font-size: $tablet-h1-fontsize;
          line-height: 1.1;
          // width: 200px;
        }

        @media all and (min-width: $screen-tablet-max-width) {
          hyphens: none;
        }
      }

      @media all and (max-width: $screen-phone-max-width) {
        display: none;
      }
    }

    .mobile-wrapper {
      display: none;
      position: relative;

      .image-wrapper {
        position: relative;
        border-radius: 40px;
        box-shadow: 4px 4px 15px rgba(23, 26, 198, 0.25), -10px 10px 20px rgba(14, 18, 214, 0.4), -20px 10px 30px rgba(19, 22, 184, 0.25), -35px 20px 50px rgba(36, 39, 196, 0.4), -60px 30px 70px #494CD7;;
        width: 48.53vw;
        height: 99.2vw;
        margin-left: -14px;

        img, video {
          position: absolute;
          top: 2.4vw;
          left: 2.4vw;
          width: 89%;
          height: 95%;

          &.border {
            width: 100%;
            height: 100%;
            top: 0;
            left: -1px;
            z-index: 1;
          }
        }

        .achievement {
          font-weight: 800;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          color: #000000;
          font-size: 9.06vw;
          line-height: 0.94;
          position: absolute;
          width: 48.8vw;
          text-align: right;
          right: -36.26vw;
          top: 0;
          z-index: 4;

          &.locale-ru {
            width: 45vw;
          }
        }
      }

      h1 {
        font-size: 9.06vw;
        line-height: 8.53vw;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #ffffff;
        text-decoration: underline;
        // width: 284px;
        margin-top: 43px;
        margin-bottom: 13px;
      }

      .description {
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 12px;
        font-size: 4.8vw;//18px;
        line-height: 1.1;
        margin-top: 8px;
        width: 80vw;
      }

      @media all and (max-width: $screen-phone-max-width) {
        display: block;
      }
    }

    @media all and (max-width: $screen-phone-max-width) {
      float: none;
      margin-left: 0;
    }
  }
</style>
