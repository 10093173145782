<template>
  <div class="flexer">
    <div class="asider" :id="sectionId" v-html="$t('components.projects.title')"></div>
    <section class="projects" v-scroll="onScroll">
      <div class="wrapper-prj">
        <project class="theproject" v-for="(project, index) in projects"
                 :key="index"
                 :index="`1-${index}`"
                 :mobileName="project.mobileName"
                 :description="project.description"
                 :link="project.link"
                 :cover="project.cover"
                 :hoverVideo="project.hoverVideo"
                 :hoverImage="project.hoverImage"
                 :nameSeparated="project.nameSeparated"
                 :achievementMobile="project.achievementMobile"
                 :achievement="project.achievement"
                 :customLetterSpacing="project.customLetterSpacing">
        </project>
      </div>
      <div class="pojects-footer" :class="`locale-${locale}`">
        <span>+</span>
        <span v-html="$t('components.projects.footer[0]')"></span>  
        <span v-html="$t('components.projects.footer[1]')"></span>
      </div>
    </section>
  </div>
</template>

<script>
    import Project from '~/components/project.vue';
    import jss from 'jss';
    import { mapGetters } from 'vuex';
    const phoneMediaQuery = '@media screen and (max-width: 767px)';
    const tabletMediaQuery = '@media screen and (min-width: 768px) and (max-width: 1365px)';
    const desktopMediaQuery = `@media screen and (min-width: 1366px)`;
    const project = 'project';
    const absolute = 'absolute';

    export default {
      name: "projects",
      components: {
        Project
      },
      data() {
        let localsBase = 'components.projects';
        let current = this.getLocalized(localsBase , 'current') || [];
        let future = this.getLocalized(localsBase , 'future') || [];

        return {
          sectionId: 'projects',
          projects: current.map((v) => {
            return {
              nameSeparated: v.nameSeparated,
              mobileName: v.mobileName,
              description: v.description,
              achievement: v.achievement,
              achievementMobile: v.achievementMobile,
              link: v.link,
              cover: v.cover,
              hoverVideo: v.hoverVideo,
              hoverImage: v.hoverImage,
              customLetterSpacing: v.customLetterSpacing
            }
          }),
          futureProjects: future.map((v) => {
            return {
              name: v.name,
              mobileName: v.mobileName,
              description: v.description,
              cover: v.cover,
            }
          })
        }
      },
      computed: {
        ...mapGetters({
          locale: 'getLocale'
        })
      }
    }
</script>

<style lang="scss" >
  
  .theproject {
    position: relative;
  }
  
  .project-1-0 a .desktop-wrapper .image-wrapper{
    bottom: 30px;
  }

  .project-1-1 a .desktop-wrapper .image-wrapper{
    bottom: 60px;
  }

  .project-1-2 a .desktop-wrapper .image-wrapper{
    bottom: 0px;
  }

  .project-1-3 a .desktop-wrapper .image-wrapper{
    bottom: 30px;
  }

  .project-1-4 a .desktop-wrapper .image-wrapper{
    bottom: 0px;
  }

  .project-1-5 a .desktop-wrapper .image-wrapper{
    bottom: 60px;
  }

  .wrapper-prj {
    display: flex;
    flex-wrap: wrap;
  }

  .projects {
      // @media all and (max-width: $screen-phone-max-width) {
      //   margin-top: unset;
      // }

    .pojects-footer {
      font-size: $desktop-h1-fontsize;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.1;
      letter-spacing: normal;
      color: white;
      // width: 678px;
      // margin-left: 326px;
      margin-bottom: 30px;
      margin-top: -30px;

      span {
        display: block;
      }

      // &.locale-ru {
      //   @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
      //     font-size: 25px;
      //     line-height: 25px;
      //     margin-top: 0px;
      //   }
      // }

      @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
        font-size: $tablet-h1-fontsize;
        line-height: 1.1;
        margin-top: 0;
        // margin-left: 200px;
      }

      @media all and (max-width: $screen-phone-max-width) {
        // width: 64.8vw;
        // margin: 44px 0 76px 0;
        margin-top: 30px;
        font-size: 9.06vw;
        line-height: 0.94;
      }
    }

    @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
      margin-top: 55px;
      // width: 524px;
    }

    @media all and (max-width: $screen-phone-max-width) {
      width: 83.73vw;
      margin-top: unset;
      margin-right: 0;

      .project:not(:nth-of-type(1)) {
        //padding-top: 40px;
      }
    }
  }
</style>
